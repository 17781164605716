import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { LayoutSessionComponent } from './shared/layouts/layout-session/layout-session.component';
import { SessionGuard } from './guards/session-auth.guard';
import { environment } from '@env/environment';

const routes: Routes = [
	{
		path: 'admin',
		loadChildren: () => import('./views/private/private.module').then(m => m.PrivateModule),
		canLoad: [SessionGuard]
	},
	{
		path: '',
		matcher: (url: UrlSegment[]) => {
			const path = url[0]?.path;

			if (!path) {
				return null;
			}
			if (
				path?.startsWith('client') ||
				path?.startsWith('group') ||
				path?.startsWith('home')
			) {
				return { consumed: [] };
			}
			return null;
		},
		children: [
			{
				path: '',
				loadChildren: () => import('./views/public/directory/directory.module').then(m => m.DirectoryModule)
			},
		]
	},
	{
		path: '',
		component: LayoutSessionComponent,
		matcher: (url: UrlSegment[]) => {
			const path = url[0]?.path;

			if (!path) {
				return null;
			}

			if (
				path.startsWith('login') ||
				path.startsWith('sign-up') ||
				path.startsWith('forgot-password') ||
				path.startsWith('logout') ||
				path.startsWith('choose-plan')
			) {
				return { consumed: [] };
			}
			return null;
		},
		children: [
			{
				path: '',
				loadChildren: () => import('./views/public/session/session.module').then(m => m.SessionModule)
			},
		]
	},
	{
		path: '',
		loadChildren: () => import('./views/business-card/business-card.module').then(m => m.BusinessCardModule)
	},
	{
		path: '**',
		redirectTo: environment.home
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'enabledBlocking',
			relativeLinkResolution: 'legacy',
			scrollPositionRestoration: 'enabled',
			onSameUrlNavigation: 'reload'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
