import { Component, OnInit, HostListener } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Utils } from '@app-shared/utils';

import { SidebarService } from '@app-services/sidebar.service';
import { SessionService } from '@app-store/session/session.service';
import { UserInfo } from '@app-store/session/session.store';

@Component({
	selector: 'app-layout-restrict-area-sidebar',
	templateUrl: './layout-restrict-area-sidebar.component.html',
	styleUrls: ['./layout-restrict-area-sidebar.component.scss']
})
export class LayoutRestrictAreaSidebarComponent implements OnInit {
	isMobile = false;
	isAdmin = false;

	itemsMenu = [
		{
			type: 'link',
			label: 'menu.dashboard',
			link: '/admin/dashboard',
			icon: 'fas fa-chart-bar',
			children: []
		},
		{
			type: 'dropdown',
			label: 'menu.personalization',
			icon: 'fal fa-cogs',
			roles: ['CLIENT', 'GROUP', 'CARDHOLDER'],
			children: [
				{
					type: 'link',
					label: 'menu.profile',
					link: '',
					active: false
				},
				{
					type: 'link',
					label: 'menu.promotedContents',
					link: '',
					active: false
				}
			]
		},
		{
			type: 'link',
			label: 'menu.clients',
			link: '/admin/client',
			icon: 'far fa-th-list',
			dropdown: false,
			children: [],
			roles: ['ADMIN', 'PRODUCT_ADMIN']
		},
		{
			type: 'link',
			label: 'menu.groups',
			link: '/admin/group',
			icon: 'far fa-layer-group',
			dropdown: false,
			children: [],
			roles: ['ADMIN', 'PRODUCT_ADMIN', 'CLIENT']
		},
		{
			type: 'link',
			label: 'menu.cardholders',
			link: '/admin/cardholder',
			icon: 'fal fa-clipboard-user',
			dropdown: false,
			children: [],
			roles: ['ADMIN', 'PRODUCT_ADMIN', 'CLIENT', 'GROUP']
		}
	];

	user: UserInfo;

	constructor(
		public router: Router,
		public sidebarService: SidebarService,
		private readonly sessionService: SessionService
	) { }

	ngOnInit() {
		this.sessionService.getUser().subscribe(u => {
			this.user = u;

			if (this.user.hasAnyRole('CLIENT', 'GROUP', 'CARDHOLDER')) {
				//define personalization and promotedContents link according to roles
				let personalizationLink: string;
				let promotedContentsLink: string;
				if (this.user.hasRole('CLIENT')) {
					personalizationLink = '/admin/personalization/' + this.user.clientId;
					promotedContentsLink = '/admin/promoted-content/client';
				} else if (this.user.hasRole('GROUP')) {
					personalizationLink = '/admin/group/' + this.user.groupId;
					promotedContentsLink = '/admin/promoted-content/group';
				} else if (this.user.hasRole('CARDHOLDER')) {
					personalizationLink = '/admin/cardholder/' + this.user.cardholderId;
					promotedContentsLink = '/admin/promoted-content/cardholder';
				}

				const personalizationMenuItem = this.itemsMenu.filter(i => i.label === 'menu.personalization')[0];
				personalizationMenuItem.children.filter(i => i.label === 'menu.profile')[0].link = personalizationLink;
				personalizationMenuItem.children.filter(i => i.label === 'menu.promotedContents')[0].link =
					promotedContentsLink;

				//remove groups e cardholders menu items if the user is an individual client
				if (!this.user.hasAnyRoleArr(['ADMIN', 'PRODUCT_ADMINN']) && this.user.individualClient) {
					this.itemsMenu = this.itemsMenu.filter(i => i.label !== 'menu.groups' && i.label !== 'menu.cardholders');
				}
			}
		});

		this.updateSidebar();
		this.updateActiveMenu();
		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(routeChange => {
			if (Utils.isMobile()) {
				this.sidebarService.active = false;
			}
			this.updateActiveMenu();
		});
	}

	updateSidebar() {
		this.sidebarService.active = !Utils.isMobile();
		this.isMobile = !Utils.isMobile();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.updateSidebar();
	}

	setActiveMenu(item) {
		setTimeout(() => {
			item.dropdown = !item.dropdown;
		}, 0);
	}

	hideMenu(): boolean {
		return !this.user || this.user.missingPlan || this.user.missingBillingInfo;
	}

	private updateActiveMenu() {
		const activeRoute = window.location.hash || window.location.pathname;
		this.itemsMenu.forEach(item => {
			item.dropdown = false;
			if (activeRoute.indexOf(item.link) !== -1) {
				item.dropdown = true;
			}
			if (item.children) {
				item.children.forEach(subItem => {
					subItem.active = false;
					if (activeRoute.indexOf(subItem.link) !== -1) {
						item.dropdown = true;
					}
				});
			}
		});
	}

	toggleSidebar() {
		this.sidebarService.minSidebar = !this.sidebarService.minSidebar;
	}
}
